import style from "./style/style.module.css";

export function About() {
  return (
    <section id="about" className={style.container}>
      <div className={style.content}>
        <div className={style.content_header}>
          <h1>About Us</h1>
          <p>
            We turn ideas into powerful digital solutions. With expertise in
            development, design, and cloud, we build scalable and user-friendly
            products for businesses of all sizes.
          </p>
        </div>
        <div className={style.content_items}>
          <div className={style.content_item}>
            <img alt="build" src="img/island-software.png" />
            <h3>Build with Code, Design with Purpose</h3>
            <p>
              Scalable development, intuitive design, and seamless cloud
              integration—everything you need to bring your vision to life.
            </p>
            <a href="/">Learn More</a>
          </div>
          <div className={style.content_item}>
            <img alt="development" src="img/island-software.png" />
            <h3>Crafting Digital Excellence</h3>
            <p>
              From full-stack development to sleek UI/UX and powerful cloud
              solutions, we create digital experiences that stand out.
            </p>
            <a href="/">Learn More</a>
          </div>
          <div className={style.content_item}>
            <img alt="scalable" src="img/island-software.png" />
            <h3>Turning Ideas into Scalable Solutions</h3>
            <p>
              We engineer robust applications, design user-centric interfaces,
              and deploy scalable cloud infrastructure for your success.
            </p>
            <a href="/">Learn More</a>
          </div>
          <div className={style.content_item}>
            <img alt="inovation" src="img/island-software.png" />
            <h3>Innovation at Every Step</h3>
            <p>
              Code that performs, designs that engage, and cloud solutions that
              scale—building future-ready products with precision.
            </p>
            <a href="/">Learn More</a>
          </div>
        </div>
      </div>
    </section>
  );
}
