import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./style/style.module.css";
import {
  faEnvelope,
  faPaperPlane,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { ScrollToSection } from "../../common/function/scroll-to";

export function Contact() {
  return (
    <section id="contact" className={style.container}>
      <p className={style.text}>
        Loosely designed in
        <strong> Figma </strong> and coded in
        <strong> Visual Studio Code </strong> by yours truly. Built with
        <strong> React </strong>
        and <strong> TypeScript</strong>, featuring stunning 3D visuals powered
        by <strong> THREE.js</strong>. Deployed as an
        <strong> Azure Static Web App</strong>, with <strong> CI/CD </strong>
        pipelines managed through <strong> Azure DevOps </strong>.
      </p>
      <div className={style.content}>
        <div>
          <img
            alt="logo"
            src="/img/island-software.png"
            style={{ width: "54px" }}
          />
          <h1>Island Sofware</h1>
          <p>
            From concept to launch, we craft cutting-edge digital solutions with
            expertise in design, development, and cloud innovation
          </p>
        </div>
        <div className={style.menu}>
          <h1>Menu</h1>
          <div className={style.menu_list}>
            <span onClick={() => ScrollToSection("home")}>Home</span>
            <span onClick={() => ScrollToSection("about")}>About</span>
            <span onClick={() => ScrollToSection("experince")}>Experince</span>
            <span onClick={() => ScrollToSection("contact")}>Contact</span>
          </div>
        </div>
        <div className={style.contact}>
          <div className={style.content_header}>
            <h1>Contact</h1>
            <p>Get in Touch</p>
          </div>
          <div className={style.input_container}>
            <div className={style.div}>
              <FontAwesomeIcon icon={faUser} className={style.input_icon} />
              <input className={style.input} placeholder="Type your name" />
            </div>
            <div className={style.div}>
              <FontAwesomeIcon icon={faEnvelope} className={style.input_icon} />
              <input className={style.input} placeholder="Type your email" />
            </div>
            <button className={style.button}>
              <FontAwesomeIcon
                size="xl"
                icon={faPaperPlane}
                className={style.send_icon}
              />
              <FontAwesomeIcon
                size="xl"
                icon={faEnvelope}
                className={style.email_icon}
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
