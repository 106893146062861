import { useEffect } from "react";
import style from "./style/style.module.css";

export function Loading() {
  useEffect(() => {
    const timeout = setTimeout(() => {
      const container = document.getElementsByClassName(style.container);
      if (!container) return;

      container[0].setAttribute("data-open", "hide");
    }, 2500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={style.container} data-open={"open"}>
      <img alt="island software" src="/img/island-software.png" />
    </div>
  );
}
