import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./style.module.css";
import {
  faBullhorn,
  faChartLine,
  faDollarSign,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";

export function Boost() {
  return (
    <section className={style.container}>
      <div className={style.content}>
        <h1>
          Boost Your Company’s Success Take Your Business to the Next Level
        </h1>
        <div className={style.content_items}>
          <div className={style.content_item}>
            <div className={style.content_item_icon}>
              <FontAwesomeIcon icon={faBullhorn} />
            </div>
            <p>Enhance your brand visibility</p>
          </div>
          <div className={style.content_item}>
            <div className={style.content_item_icon}>
              <FontAwesomeIcon icon={faUserCheck} />
            </div>
            <p>Attract more loyal customers</p>
          </div>
          <div className={style.content_item}>
            <div className={style.content_item_icon}>
              <FontAwesomeIcon icon={faChartLine} />
            </div>
            <p>Stay ahead of the competition</p>
          </div>
          <div className={style.content_item}>
            <div className={style.content_item_icon}>
              <FontAwesomeIcon icon={faDollarSign} />
            </div>
            <p>Increase your revenue potential</p>
          </div>
        </div>
      </div>
    </section>
  );
}
