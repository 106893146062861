import { About } from "./module/about/about";
import { Footer } from "./module/footer/footer";
import { Header } from "./module/header/header";
import { Cool } from "./module/cool/cool";
import { Welcome } from "./module/welcome/welcome";
import { Experience } from "./module/experience/experience";
import { Loading } from "./module/loading/loading";
import { Contact } from "./module/contact/contact";
import { Boost } from "./module/boots/boost";

function App() {
  return (
    <main className="main" id="main">
      <Header />
      <Welcome />
      <About />
      <Boost />
      <Experience />
      <Cool />
      <Contact />
      <Footer />
      <Loading />
    </main>
  );
}

export default App;
