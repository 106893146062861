import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./style/style.module.css";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

export function Welcome() {
  return (
    <>
      <section className={style.container} id="home">
        <img alt="background" src="/img/bg.webp" className={style.bg} />
        <div className={style.bg_shadow} />
        <div className={style.content}>
          <div className={style.text_content}>
            <h1 className={style.main_content}>
              Let's build from here, together. Transforming Ideas into Reality
            </h1>
            <p className={style.info}>
              From concept to launch, we craft cutting-edge digital solutions
              with expertise in design, development, and cloud innovation
            </p>
          </div>
          <button className={style.button}>
            <span>Contact Us</span>
            <FontAwesomeIcon icon={faPhone} />
          </button>
          <div className={style.exp_content}>
            <div className={style.exp_content_item}>
              <h1>250+</h1>
              <p>Projects Completed</p>
            </div>
            <div className={style.exp_content_item}>
              <h1>13+</h1>
              <p>Years Experience</p>
            </div>
            <div className={style.exp_content_item}>
              <h1>98%</h1>
              <p>Client Satisfaction</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
