import { Link } from "react-router-dom";
import style from "./style/style.module.css";
import menu from "./style/menu.module.css";
import { CSSProperties, useEffect, useState } from "react";
import { ScrollToSection } from "../../common/function/scroll-to";

export function Header() {
  const [popup, setPopup] = useState(false);
  const [active, setActive] = useState("home");

  useEffect(() => {
    function handleScroll() {
      var header = document.getElementById("header")!;
      if (!header) return;

      header.setAttribute("data-scroll", `${window.scrollY > 15}`);

      const home = document.getElementById("home");
      const about = document.getElementById("about");
      const contact = document.getElementById("contact");
      const experience = document.getElementById("experience");

      if (!home) return;
      if (!about) return;
      if (!contact) return;
      if (!experience) return;

      const threshold = window.innerHeight / 1.5;
      const homeTop = home.getBoundingClientRect().top;
      const aboutTop = about.getBoundingClientRect().top;
      const contactTop = contact.getBoundingClientRect().top;
      const experienceTop = experience.getBoundingClientRect().top;

      if (homeTop < threshold && homeTop >= 0) setActive("home");
      if (aboutTop < threshold && aboutTop >= 0) setActive("about");
      else if (experienceTop < threshold && experienceTop >= 0)
        setActive("experience");
      else if (contactTop < threshold && contactTop >= 0) setActive("contact");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={style.header} id="header">
      <img
        data-active={popup}
        onClick={() => {
          setPopup(!popup);
          document
            .getElementById("header")!
            .setAttribute("data-active", `${!popup}`);
        }}
        src="/img/menu.png"
        alt=""
        className={menu.menu}
      />
      <nav className={style.nav} data-active={popup}>
        <ul className={`${style.ul}`}>
          {["home", "about", "experience", "contact"].map((item, i) => (
            <li
              key={i}
              data-active={active === item}
              data-anim-translate-up="true"
              style={{ "--animation-delay": i + 10 } as CSSProperties}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setActive(item);
                  setPopup(!popup);
                  e.preventDefault();
                  ScrollToSection(item);
                }}
                data-active={active === item}
              >
                <code>
                  {`function`}{" "}
                  <span
                    className={style.function}
                  >{`${item.toLowerCase()}`}</span>
                  <span className={style.bracket}>()</span>
                </code>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}
