import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { Earth } from "../computer/earth";
import { OrbitControls } from "@react-three/drei";
import style from "./style/style.module.css";
import { FallbackLoader } from "../computer/fallback-loader";
import { ScrollToSection } from "../../common/function/scroll-to";

export function Cool() {
  return (
    <section className={style.container}>
      <div className={style.content}>
        <div className={style.cool}>
          <h2>{`Hey ! You wanna see something cool ?`}</h2>
          <div>
            <p className={style.p}>
              Explore the magic of code with a 3D twist!
            </p>
            <p className={style.p}>Ready to bring your ideas to life?</p>
            <p className={style.p}>
              Join our world of innovation -{" "}
              <strong
                className={style.contact}
                onClick={() => ScrollToSection("contact")}
              >
                contact us
              </strong>{" "}
              today!
            </p>
          </div>
        </div>
        <div className={style.canvas}>
          <Canvas
            camera={{ position: [2, 0, 1], near: 0.1, far: 200, fov: 50 }}
          >
            <Suspense fallback={<FallbackLoader />}>
              <Earth />
              <OrbitControls enableZoom={false} enablePan={true} autoRotate />
            </Suspense>
          </Canvas>
        </div>
      </div>
    </section>
  );
}
