import style from "./style/style.module.css";

export function Footer() {
  return (
    <>
      <div className="divider" />
      <footer className={style.footer}>
        <p className={style.p}>Design & Develop by</p>
        <img alt="" width={40} height={40} src="/img/island-software.png" />
        <p className={style.p}>Cagri Gulhan</p>
      </footer>
    </>
  );
}
