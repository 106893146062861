import style from "./style/style.module.css";

export function Experience() {
  return (
    <section id="experience" className={style.container}>
      <div className={style.content}>
        <div className={style.content_header}>
          <h1>Experience</h1>
          <p className={style.info}>
            Empowering your digital journey with expertise in modern
            development, design, and cloud solutions.
          </p>
        </div>

        <div className={style.items}>
          <div className={style.item}>
            <div>
              <div className={style.header}>
                <h3>Software Development</h3>
              </div>
              <p className={style.p}>
                Experienced in building real-time applications with C# and .NET
                Core SignalR, leveraging robust database solutions for scalable
                and responsive software development.
              </p>
              <div className={style.languages}>
                <p className={style.language}>.Net Core</p>
                <p className={style.language}>MS Sql</p>
                <p className={style.language}>SignalR</p>
                <p className={style.language}>PubSub</p>
              </div>
            </div>
          </div>

          <div className={style.item}>
            <div>
              <div className={style.header}>
                <h3>Azure Expertise</h3>
              </div>
              <p className={style.p}>
                Proficient in managing CI/CD pipelines using Azure DevOps,
                streamlining build and deployment processes for consistent and
                reliable application delivery. Experienced in utilizing Azure
                Portal for resource management, monitoring, and scaling
                cloud-based solutions to meet dynamic business needs.
              </p>
              <div className={style.languages}>
                <p className={style.language}>Azure DevOps</p>
                <p className={style.language}>Azure Portal</p>
                <p className={style.language}>App Service</p>
                <p className={style.language}>Database</p>
                <p className={style.language}>Functions</p>
                <p className={style.language}>CI/ CD</p>
                <p className={style.language}>Static Web App</p>
              </div>
            </div>
          </div>

          <div className={style.item}>
            <div>
              <div className={style.header}>
                <h3>Frontend Development</h3>
              </div>
              <p className={style.p}>
                Proficient in crafting responsive and dynamic user interfaces
                using modern frameworks like React and TypeScript, with a focus
                on performance and seamless user experiences.
              </p>
              <div className={style.languages}>
                <p className={style.language}>React</p>
                <p className={style.language}>TypeScript</p>
                <p className={style.language}>JavaScript</p>
                <p className={style.language}>HTML & CSS</p>
              </div>
            </div>
          </div>

          <div className={style.item}>
            <div>
              <div className={style.header}>
                <h3>Figma Design</h3>
              </div>
              <p className={style.p}>
                Skilled in using Figma to develop creative and functional design
                solutions, emphasizing clarity, consistency, and user
                engagement.
              </p>
              <div className={style.languages}>
                <p className={style.language}>UI / UX</p>
                <p className={style.language}>Prototype</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
